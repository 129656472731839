.StatsDiv{
    color: white;
    width: 100%;
    text-align: left;
    font-size: small;
    /* padding: 0 20px 20px 20px; */
}

.exitStats{
    text-align: right;
    cursor: pointer;
    padding-right: 5%;
}

.statsTitleDiv{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.statsTitle{
    font-size: medium;
}

.overallStats{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.statTotals{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 10% 5% 10%;
}

.statTotalNumber{
    text-align: center;
    font-size: x-large;
}

.statTotalDescription{
    text-align: center;
    margin: 0;
    font-size: large;
    white-space: nowrap;
}

.guessChartDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.barChartDiv{
    width: min(80%, 800px);
    height: 30vh;
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }