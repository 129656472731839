.inputArea-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.loss-text{
    font-size: .7em;
    text-align: left;
    margin: 0 0 .3em 0;
}

.win-text{
    font-size: .7em;
    text-align: center;
    margin: 0 0 .3em 0;
}

.alert-div{
    position: fixed;
    top: 40%;
    align-self: center;
}