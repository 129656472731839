.App {
  text-align: center;
}

.MainPage {
  background-color: #111212;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.TitleArea {
  display: block;
  max-height: 5%;
  width: 80%;
}

.ImageArea {
  display: block;
  /* max-height: 55%; */
  height: 45%;
  max-width: 80%;
}

.InputArea {
  display: block;
  height: 50%;
  width: 80%;
}

.HowToArea {
  display: flex;
  position: absolute;
  z-index: 100;
  top: 10%;
  max-width: min(80%, 800px);
  background-color: #151617;
  border-radius: 4px;
  border-style: solid;
  border-width: 5px;
  border-color: white;
}

.StatsArea {
  display: flex;
  position: absolute;
  z-index: 100;
  top: 10%;
  width: min(80%, 800px);
  background-color: #151617;
  border-radius: 4px;
  border-style: solid;
  border-width: 5px;
  border-color: white;
}

@media (max-width: 1100px) {
  .TitleArea {
    display: block;
    max-height: 5%;
    width: 80%;
  }

  .ImageArea {
    display: block;
    height: 55%;
    /* max-height: 50%; */
    max-width: min(95%, 800px);
  }

  .InputArea {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    max-width: 95%;
    width: 95%;
}
}
