.input-box{
    /* aspect-ratio: 1/1; */
    width: 7.7%; 
    text-align: center;
    color: white;
    font-size: .7em;
    border: 0;
    outline: 0;
    opacity: 1;
    margin: 0 .5% .3% 0;
    padding: 0;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    -webkit-text-fill-color: rgba(255, 255, 255, 1); 
    -webkit-opacity: 1;
    -webkit-text-size-adjust: 100%;
    
}

.input-box-normal{
    background: #4a4a4a;
}

.input-box-correct{
    background: #234020;
}

.input-box-almost{
    background: #d9d441;
}

.input-box-incorrect{
    background: #1a1b1c;
}

.transition{
    transition: all 0.5s ease-in;
}