.keyboard {
    max-width: 500px;
    margin-top: 1em;
  }

.keyboard-div{
    width: auto;
}

.keyboard > div {
    margin: 1px;
    width: 1.5em;
    height: 2em;
    background: #4a4a4a;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}

.keyboard > div.green {
    background: #234020;
    color: #fff;
    transition: all 0.5s ease-in;
}

.keyboard > div.yellow {
    background: #d9d441;
    color: #fff;
    transition: all 0.5s ease-in;
}

.keyboard > div.black {
    background: #1a1b1c;
    color: #fff;
    transition: all 0.5s ease-in;
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

@media (max-width: 1100px) {
    .keyboard-div{
        width: 100%;
    }

    .keyboard {
        width: 100%;
        max-width: 1100px;
        margin-top: 1em;
    }
    .keyboard > div {
        width: 9%;
        height: min(3em, 6vh)
    }   
}  