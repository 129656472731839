.enter-guess{
    font-size: .7em;
    text-align: left;
    margin: 0 0 .3em 0;
}

.user-guess-div{
    display: flex;
    min-width: 0;
    flex-direction: column;
    height: 100%;
    align-items: center;
    /* min-width: 19.2em; */
}

.guess-container{
    display: flex;
    flex-direction: column;
    height: 15%;   
    padding: 0 0 .28% 0;
}

.user-guess-container{
    display: flex;
    flex-direction: column;
    height: 25%;
    width: 48%;  
    margin: 0 0 1% 0;
}


.letter-row-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.letter-row-div{
    display: flex;
    flex-direction: row;
    min-width: 15.5em;
    justify-content: flex-start;
    margin: 0 0 .1% 0;
    /* margin: .1em 0 .1em 0; */
}

.letter-row-div-bar{
    display: flex;
    flex-direction: row;
    min-width: 15.5em;
    justify-content: flex-start;
    width: 98%;
    /* padding: 0 0 1% 0; */
    /* margin: 0 0 .1% 0; */
}


.submit-button-div{
    display: flex;
    flex-direction: row;
    min-width: 15.5em;
    justify-content: right;
    margin: .2em 0 .2em 0
}

@media (max-width: 1100px) {
    .user-guess-container{
        width: min(94%, 780px);
        height: 30%;   
    }
}

