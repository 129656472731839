.HowToPlayDiv{
    color: white;
    width: 100%;
    text-align: left;
    font-size: small;
    padding: 0 4% 4% 4%;
    max-height: 80vh;
}

.exitHowTo{
    text-align: right;
    cursor: pointer;
}

.rulesDiv{
    display: flex;
    flex-direction: column;
}

.howToPlayTitle{
    font-size: medium;
    text-align: center;
    border-bottom: solid;
    border-width: 1px;
    border-color: white;
}

.bulletText{
    font-size: medium;
}

.examplesDiv{
    display: flex;
    flex-direction: column;
}

.examplesTitle{
    font-size: medium;
    text-align: center;
    border-bottom: solid;
    border-width: 1px;
    border-color: white;
}

.examplesSubtitles {
    font-size: small;
    text-align: center;
}

.examplesExplain{
    display: flex;
}

.examplesExplainImages{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-right: 2%;
    max-width: 45%;
}

.examplesExplainImagesObject{
    max-width: 100%;
}

.examplesExplainText{
    display: flex;
    flex-direction: column;
    border-left: solid;
    border-width: 1px;
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

@media (max-height: 1000px) and (max-width: 1000px) {
    .bulletText{
        font-size: x-small;
    }
} 

@media (max-height: 700px) and (max-width: 1000px) {
    .bulletText{
        font-size: xx-small;
    }

    .howToPlayTitle{
        font-size: x-small;
    }

    .examplesSubtitles{
        font-size: x-small;
    }

    .examplesTitle{
        font-size: x-small;
    }
}  