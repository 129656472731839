.ImageDiv {
    display: flex;
    justify-content: center;
    height: 90%;
    margin: 10px;
}

.ImageContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    max-height: 100%;
    padding: 1% 2% 1% 0;
    /* max-height: 100%; */
    /* min-width: 50%;
    justify-content: center; */
}

.ImageObject {
    display: flex; 
    /* min-height: 0; */
    /* max-width: 100%; */
    max-height: 100%;
    max-width: 100%;
    /*min-width: 50%; */
    justify-content: center;
}

.ImageObject >  canvas{
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}

.PrevGuessSection{
    display: flex;
    width: 50%;
    justify-content: center;
}

@media (max-width: 1100px) {
    .ImageDiv {
        display: flex;
        min-height: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 95%;
        margin: 10px;
    }

    .ImageContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        padding: 0 1% 1% 1%;
        /* max-height: 100%; */
        /* min-width: 50%;
        justify-content: center; */
    }

    .ImageObject{
        display: flex;
        /* min-height: 0; */
        max-width: 100%;
        /* min-width: 50%; */
        justify-content: center;
    }

    .ImageObject >  canvas{
        max-height: 100%;
        max-width: 100%;
    }

    .PrevGuessSection{
        display: flex;
        flex-direction: column;
        /* min-height: 0; */
        height: 50%;
        /* width: 100%; */
        width: 100%;
        /* min-width: 0; */
        justify-content: center;
    }
}

