.TitleDiv {
    display: flex;
    max-height: 100%;
    max-width: 100%;

}

.PixdleLogoDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* aspect-ratio: 1/1; */
    max-height: 100%;
    min-width: 0;
    max-width: 100%;
}

.TitleImageObject {
    min-width: 0;
    max-width: 100%;
    max-height: 100%;
    margin-right: auto;
}


.IconDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* aspect-ratio: 1/1; */
    margin: 0 1% 0 1%;
    max-height: 100%;
    max-width: 10%;
    cursor: pointer;
}

.IconObject {
    max-width: 100%;
    max-height: 90%;
}

.DropdownDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 2% 0 1%;
    cursor: pointer;
}
